$tundora: #444444;
$navigationHeight: 4.5em;

.Banner {
    // Non-Styling

    width: 100%;
    height: calc(100vh - #{ $navigationHeight });
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    position: relative;
    overflow: hidden;

    .Image {
        // Non-Styling

        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        img {
            opacity: 0.9;

            // Non-Styling

            width: 100%;
            height: 100%;

            object-fit: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .Wrapper {
        text-align: center;
        
        // Non-Styling

        position: relative;

        width: 90%;
        margin: 0 auto;

        .Main {
            font-family: 'Josefin Slab', sans-serif;
            font-size: 5vh;
            color: white;

            animation: fadeIn 0.75s 0s ease-out forwards;

            // Non-Styling

            line-height: 1.125em;
            text-transform: uppercase;
            text-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
        }

        .Line {
            width: 4em;
            height: 2px;

            background-color: white;

            opacity: 0;
            animation: fadeIn 0.75s 0s ease-out forwards;

            // Non-Styling

            margin: 0.75em auto 2em;
        }

        // .Description {
        //     opacity: 0;
        //     animation: fadeIn 0.75s 0.25s ease-out forwards;

        //     // Non-Styling

        //     display: none;
        // }

        .Description {
                display: block;
                width: 80%;
                margin: 0 auto;

                font-size: 1.25em;
                font-weight: 300;

                text-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
                line-height: 1.5em;
                
                color: white;
            }
    }

    .Links {
        // Non-Styling
        
        position: relative;
        
        .Left, .Right {
            width: 11em;
            height: 3.25em;
            line-height: 3.25em;

            text-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
            text-transform: uppercase;
            
            opacity: 0;
            animation: fadeIn 0.75s 0.5s ease-out forwards;

            // Non-Styling

            display: block;

            text-decoration: none;
            text-align: center;

            transition: all 0.15s;
        }

        .Left {
            color: white;
            border: 2px solid white;
            
            // Non-Styling

            margin-bottom: 1em;

            &:hover {
                background-color: white;
                color: $tundora;
            }
        }

        .Right {
            font-weight: 600;
            color: $tundora;
            border: 2px solid white;

            background-color: white;

            &:hover {
                background-color: transparent;
                color: white;
            }
        }
    }

    .Scroll {        
        // Non-Styling
        
        position: absolute;
        bottom: -0.5em;
        left: 50%;
        transform: translateX(-50%);

        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;

        transition: all 0.15s ease-out;
        cursor: pointer;

        border: none;
        background: none;
        overflow: hidden;

        &:hover {
            bottom: 0;
        }

        .Circle {
            width: 1.5em;
            height: 1.5em;

            border: 2.5px solid white;
            border-radius: 2em;

            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            
            opacity: 0;
            animation: fadeIn 0.75s 0.75s ease-out forwards;

            // Non-Styling
            
            display: block;
        }

        .Knob {
            width: 2.5px;
            height: 1.75em;

            background-color: white;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            
            opacity: 0;
            animation: fadeIn 0.75s 0.75s ease-out forwards;
        }
        display: none;
    }

    @keyframes fadeIn {
        from {
            transform: translateY(0.5em);
            opacity: 0;
        }
    
        to { 
            transform: translateY(0);
            opacity: 1;
        }
    }
}

@media (min-width: 720px) {
    //$navigationHeight: 5em;
    $navigationHeight: calc(10em + 2px);

    .Banner {
        //height: calc(100vh - #{ $navigationHeight });
        width: 100%;
        height: calc(100vh - #{ $navigationHeight });
        margin: 0;

        .Wrapper {
            width: 90%;
            margin-bottom: 2em;

            .Main {
                font-size: 7vh;
            }

            .Line {
                margin-bottom: 1.75em;
            }

            .Description {
                display: block;
                width: 80%;
                margin: 0 auto;

                font-size: 1.25em;
                font-weight: 300;

                text-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
                line-height: 1.5em;
                
                color: white;
            }
        }

        .Links {
            .Left, .Right {
                display: inline-block;
                margin: 0 0.75em;
            }
        }
    }
}

@media (min-width: 1025px) {
    $navigationHeight: calc(10em + 2px);

    .Banner {
        width: 100%;
        height: calc(100vh - #{ $navigationHeight });
        margin: 0;

        left: 50%;
        transform: translateX(-50%);

        .Wrapper {
            width: 70%;

            .Main {
                font-size: 4em;
            }

            .Description {
                width: 70%;
            }
        }

        .Links {
            .Left, .Right {
                font-size: 0.9em;
            }
        }

        .Scroll {        
            // Non-Styling
            
            position: absolute;
            bottom: -0.5em;
            left: 50%;
            transform: translateX(-50%);
    
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
    
            transition: all 0.15s ease-out;
            cursor: pointer;
    
            border: none;
            background: none;
            overflow: hidden;
    
            &:hover {
                bottom: 0;
            }
    
            .Circle {
                width: 1.5em;
                height: 1.5em;
    
                border: 2.5px solid white;
                border-radius: 2em;
    
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                
                opacity: 0;
                animation: fadeIn 0.75s 0.75s ease-out forwards;
    
                // Non-Styling
                
                display: block;
            }
    
            .Knob {
                width: 2.5px;
                height: 1.75em;
    
                background-color: white;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                
                opacity: 0;
                animation: fadeIn 0.75s 0.75s ease-out forwards;
            }
           
        }
    }
}

@media (max-height: 680px) {
    .Banner {
        .Scroll {
            display: none;
        }
    }
}
