$akaroa: #D7C2A4;
$tundora: #444444;

.Gallery {
    width: 100%;
    margin-top: 2em;

    // Non-Styling

    display: flex;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;

    .Picture {
        margin: 0.5em 0;

        // Non-Styling

        width: 90vw;
        height: 90vw;

        overflow: hidden;

        &:hover {
            .Expand {
                transform: translateY(-100%);
                opacity: 1;
            }
        }

        .Image {
            opacity: 0.9;
            
            // Non-Styling

            width: 100%;
            height: 100%;

            object-fit: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .Expand {
            font-family: 'Montserrat';
            font-weight: 300;

            color: $tundora;
            background-color: rgba(255, 255, 255, 0.9);
            opacity: 0;

            // Non-Styling
            
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            text-transform: uppercase;
            cursor: pointer;
            
            transform: translateY(-50%);
            transition: all 0.3s;
        }
    }

    .Modal {
        background-color: rgba(0, 0, 0, 0.6);

        // Non-Styling

        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        z-index: 500;
        
        display: flex;
        justify-content: center;
        align-items: center;
        
        .Wrapper {
            position: relative;

            animation: expand 0.25s 0s ease-out forwards;

            img {
                min-width: 3em;
                min-height: 3em;

                max-width: 82.5vw;
                max-height: 82.5vh;
                
                box-shadow: 0 0 12px rgba(0, 0, 0, 0.04);
                
                // Non-Styling

                object-fit: cover;
                background-position: center;
                background-repeat: no-repeat;
            }

            .Close {
                $size: 1.75em;

                width: $size;
                height: $size;

                background-color: white;
                box-shadow: 0 0 12px rgba(0, 0, 0, 0.04);
                
                // Non-Styling

                display: flex;
                justify-content: center;
                align-items: center;

                position: absolute;
                top: calc(#{ $size } / -2);
                right: calc(#{ $size } / -2);

                transition: all 0.15s;
                cursor: pointer;

                border: none;

                &:hover {
                    background-color: $akaroa;

                    .Icon {
                        filter: brightness(1);
                    }
                }

                .Icon {
                    background-image: url('../../assets/icons/close.png');

                    width: 65%;
                    height: 65%;
                    
                    filter: brightness(0.2);

                    // Non-Styling

                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    
                    transition: all 0.15s;
                }
            }

            .Previous, .Next {
                width: 1.75em;
                height: 75%;

                // Non-Styling

                position: absolute;

                top: 50%;
                transform: translateY(-50%);

                display: flex;
                align-items: center;

                padding: 0;
                background-color: transparent;
                border: none;

                &:hover {
                    i {
                        filter: brightness(0.8);
                    }
                }

                i {
                    background-image: url('../../assets/icons/arrow.png');

                    width: 100%;
                    height: 2em;
                    
                    // Non-Styling
                    
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    
                    transition: all 0.15s;
                }
            }

            .Previous {
                left: -1.75em;

                i {
                    transform: rotateZ(180deg);
                }
            }

            .Next {
                right: -1.75em;
            }
            
            @keyframes expand {
                from {
                    transform: translateY(-6em);
                    opacity: 0.25;
                }

                to {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }
}

@media (min-width: 720px) {
    .Gallery {
        .Picture {
            width: 28vw;
            height: 28vw;

            margin: 0.35em;
        }
        
        .Modal {
            .Wrapper {
                .Close {
                    $size: 2.5em;
    
                    width: $size;
                    height: $size;

                    top: calc(#{ $size } / -2);
                    right: calc(#{ $size } / -2);
                }

                .Previous, .Next {
                    width: 2em;
                }

                .Previous {
                    left: -2em;
                }

                .Next {
                    right: -2em;
                }
            }
        }
    }
}

@media (min-width: 1025px) {
    .Gallery {
        .Picture {
            width: 23vw;
            height: 23vw;
        }
        
        .Modal {
            .Wrapper {
                .Close {
                    $size: 2em;
    
                    width: $size;
                    height: $size;

                    top: calc(#{ $size } / -2);
                    right: calc(#{ $size } / -2);
                }
            }
        }
    }
}

