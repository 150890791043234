$akaroa: #D7C2A4;
$tundora: #444444;

.Widgets {
    // Non-Styling

    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;

    .Widget {
        width: 70%;
        margin-top: 3em;

        .Icon {
            width: 100%;
            height: 70vw;

            background-color: white;
            border: 1.5px solid $akaroa;
            border-radius: 50%; 

            // Non-Styling
            
            display: flex;
            justify-content: center;
            align-items: center;
            
            transition: all 0.3s;
            cursor: pointer;

            &:hover {
                background-color: lighten($akaroa, 10);

                img {
                    width: 65%;
                    height: 65%;
                }
            }

            img {
                width: 60%;
                height: 60%;
                
                // Non-Styling

                object-fit: contain;
                background-repeat: no-repeat;
                background-position: center;

                transition: all 0.3s;
            }
        }

        .Name {
            font-size: 1.5em;
            font-weight: 300;
            color: $tundora;

            text-transform: uppercase;
            text-align: center;

            margin: 2em 0 0;
            
            cursor: pointer;
        }
    }
}

@media (min-width: 720px) {
    .Widgets {
        .Widget {
            width: 35%;
            margin: 2em;

            .Icon {
                height: 35vw;
            }
        }
    }
}

@media (min-width: 1025px) {
    .Widgets {
        .Widget {
            width: 16em;
            margin: 0 2em;

            .Icon {
                height: 16em;
            }
        }
    }
}
