$akaroa: #D7C2A4;

.Button {    
    width: 11em;
    height: 3.25em;

    line-height: 3.25em;
    
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
    text-transform: uppercase;
    
    background-color: $akaroa;
    border: 2px solid $akaroa;
    color: white;

    // Non-Styling

    display: block;
    text-decoration: none;
    text-align: center;
    transition: all 0.15s;

    &:hover {
        background-color: transparent;
        color: $akaroa;
    }
}

@media (min-width: 1025px) {
    .Button {
        font-size: 0.9em;
    }
}
