$tundora: #444444;
$grey: rgb(194, 194, 194);

.Input, .Textarea {
    border-bottom: 1px solid $grey;

    font-family: 'Open Sans', sans-serif;
    color: $tundora;

    // Non-Styling

    width: 100%;
    height: 2em;

    padding: 0;
    margin-bottom: 1.25em;

    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    transition: color 0.15s;

    &::placeholder {
        font-weight: 300;
        text-transform: capitalize;
        color: $grey;

        // Non-Styling
        
        transition: color 0.15s;
    }

    &:focus {
        border-bottom: 1.25px solid $tundora;

        &::placeholder {
            color: $tundora;
        }
    }
}

.Input {
    // Non-Styling

    height: 2em;
}

.Textarea {
    // Non-Styling

    min-height: 8em;
    max-height: 14em;
}
