$akaroa: #D7C2A4;
$tundora: #444444;
$copper: #B87332;

.Catalog {
    // Non-Styling

    width: 100%;

    .List {
        // Non-Styling

        width: 100%;

        .Item {
            // Non-Styling

            width: 100%;
            min-height: 1.75em;

            margin-bottom: 1.25em;

            &:last-of-type {
                margin-bottom: 0;
            }

            .Line {
                // Non-Styling

                width: 100%;

                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .Name, .Price {
                    font-family: 'Montserrat', sans-serif;
                    color: $tundora;
                }

                .Price {
                    min-width: 5em;
                    text-align: right;

                    .Discounted {
                        text-decoration: line-through;
                        color: $copper;
                    }
                }
            }

            .Description {
                font-family: 'Montserrat', sans-serif;
                font-size: 0.8em;
                color: #A6A6A6;
                
                // Non-Styling

                margin: 5px 0 0 1px;
            }
        }
    }

    .Options {
        margin-top: 3em;

        .Button {
            background-color: $akaroa;
            border-radius: 0em;

            font-family: 'Montserrat', sans-serif;
            font-weight: 300;
            text-transform: capitalize;
            color: white;

            // Non-Styling

            margin-right: 1.25em;
            padding: 0.8em 1.35em;

            border: none;

            transition: all 0.15s;

            &:hover {
                background-color: darken($akaroa, 10);
            }
        }    

        .Link {
            font-family: 'Montserrat', sans-serif;
            text-transform: capitalize;
            color: $copper;        
            
            // Non-Styling

            text-decoration: none;
            transition: all 0.15s;
            outline: none;

            &:hover {
                color: darken($copper, 10);
            }
        }
    }
}
