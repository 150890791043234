$tundora: #444444;
$akaroa: #D7C2A4;

body {
    // Non-Styling

    margin-top: 4.5em;
}

.Upper, .Line, .Lower .Links {
    // Non-Styling

    display: none;
}

.Lower {
    width: 90%;
    height: 4.5em;

    padding: 0 5%;
    
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    
    // Non-Styling

    position: fixed;
    top: 0;
    z-index: 250;

    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .Social {
        // Non-Styling

        display: flex;
        justify-content: space-between;
        align-items: center;

        .Facebook, .Instagram, .Phone {
            width: 1.5em;
            height: 1.5em;     

            filter: brightness(0.35);
            
            // Non-Styling               

            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            
            outline: none;
            transition: all 0.15s;

            &:hover {
                filter: brightness(0.15);                        
            }
        }

        .Facebook {
            background-image: url('../../assets/icons/facebook.png');
        }
        
        .Instagram {
            margin: 0 0.75em;
            background-image: url('../../assets/icons/instagram.png');
        }

        .Phone {
            background-image: url('../../assets/icons/phone.png');
        }
    }

    .Logo {
        width: 5.5em;
        height: 4em;
        
        background-image: url('../../assets/icons/logo.svg');

        // Non-Styling

        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;  
        
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);

        z-index: 1000;
        outline: none;
    }

    .Menu {
        background-image: url('../../assets/icons/menu.png');
        filter: brightness(0.2);

        // Non-Styling

        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        
        border: none;
        background-color: transparent;

        width: 2em;
        height: 2em;
    }

    .Links {
        background-color: white;

        // Non-Styling
        
        display: none;
        position: fixed;
        
        width: 100%;

        top: 0;
        left: 0;        
        
        justify-content: center;
        align-items: center;
        flex-flow: column;

        .Link {
            // Non-Styling

            margin: 1.5em 0;

            a {
                font-size: 1.25em;
                font-family: 'Montserrat', sans-serif;
                color: $tundora;

                // Non-Styling

                padding: 0.5em 0;
                text-transform: uppercase;
                text-decoration: none;
                
                outline: none;

                .Underline {
                    height: 3px;
                    margin: 0.75em auto 0;

                    background-color: $akaroa;
                    border-radius: 3px;
                    
                    // Non-Styling

                    width: 0;

                    position: absolute;
                    left: 0;
                    right: 0;
                }
            }

            .Active {
                // Non-Styling

                position: relative;

                &:hover {
                    .Underline {
                        background-color: $akaroa;
                    }
                }

                .Underline {
                    animation: underline 0.3s 0s ease forwards;

                    @keyframes underline {
                        from {
                            width: 0;
                        }

                        to {
                            width: 3em;
                        }
                    }
                }
            }
        }

        .Book {
            // Non-Styling

            margin-top: 3em;
            
            a {
                background-color: $akaroa;
                border-radius: 5em;

                font-size: 1.1em;
                color: white;

                // Non-Styling

                padding: 0.85em 2em;

                transition: all 0.15s;

                &:hover {
                    background-color: darken($akaroa, 10);
                }
            }
        }
    }
}

.Reveal {
    // Non-Styling

    border-bottom: none;

    .Links {
        // Non-Styling

        display: flex;
        animation: expand 0.3s 0s ease-out forwards;

        @keyframes expand {
            from {
                height: 0;
                opacity: 0;
            }

            to {
                height: 100vh;
                opacity: 1;
            }
        }
    }
    
    .Menu {
        background-image: url('../../assets/icons/close.png');
    }
}

@media (min-width: 720px) {
    body {
        margin: 0;
    }   

    .Upper {
        height: 5.5em;
        background-color: white;
        
        // Non-Styling

        width: 92%;
        margin: 0 auto;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .Social {
            width: 4.5em;

            // Non-Styling

            display: flex;
            justify-content: space-between;
            align-items: center;

            .Facebook, .Instagram {
                width: 1.5em;
                height: 1.5em;     

                filter: brightness(0.35);
                
                // Non-Styling               

                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                
                outline: none;
                transition: all 0.15s;

                &:hover {
                    filter: brightness(0.25);                        
                }
            }

            .Facebook {
                background-image: url('../../assets/icons/facebook.png');
            }
            
            .Instagram {
                background-image: url('../../assets/icons/instagram.png');
            }
        }

        .Logo {
            width: 5em;
            height: 5em;
            
            background-image: url('../../assets/icons/logo.svg');

            // Non-Styling

            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;  
            
            outline: none;
        
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
        }

        .Contacts {
            width: 4.5em;

            // Non-Styling

            display: flex;
            align-items: center;
            justify-content: space-between;

            .Contact {
                // Non-Styling

                display: flex;
                align-items: center;
                
                transition: all 0.15s;
                cursor: pointer;

                &:hover {
                    .Icon {
                        filter: brightness(0.25);  
                    }

                    .Info {
                        color: black;
                    }
                }

                .Icon {
                    width: 1.5em;
                    height: 1.5em;          
                    
                    filter: brightness(0.35);
                    
                    // Non-Styling

                    object-fit: contain;
                    background-repeat: no-repeat;
                    background-position: center;

                    transition: all 0.15s;                        
                }

                .Info {
                    display: none;

                    font-size: 0.9em;
                    color: $tundora;
                    
                    // Non-Styling

                    text-transform: none;
                    text-decoration: none;
                    
                    outline: none;
                    transition: all 0.15s;
                }
            }
        }
    }

    .Line {
        height: 2px;

        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.05);
        
        // Non-Styling

        display: block;
        width: 100%;
    }

    .Lower {
        position: sticky;
        top: -2px;

        width: 80%;
        height: 4.5em;

        padding: 0 10%;

        .Social {
            display: none;
        }

        .Logo, .Menu {
            display: none;
        }

        .Links {
            display: flex;
            justify-content: space-around;
            align-items: center;

            flex-flow: row;

            position: relative;
            top: 0;

            .Link {
                a {
                    font-size: 0.75em;

                    // Non-Styling 
                    
                    position: relative;

                    .Underline {
                        height: 2px;
                    }

                    &:hover {
                        .Underline {
                            background-color: rgba(0, 0, 0, 0.05);
                        }
                    }
                }
            }

            .Book {
                margin: 0;
            }
        }
    }
}

@media (min-width: 1025px) {
    .Upper {
        width: 100%;

        .Social {            
            .Facebook, .Instagram {
                width: 1.5em;
                height: 1.5em; 
            }
        }

        .Logo {
            width: 5.25em;
            height: 5.25em;
        }

        .Contacts {
            width: auto;

            .Contact {

                .Icon {
                    width: 1.5em;
                    height: 1.5em;     

                    margin: 0 0.5em 0 1.25em;
                }

                .Info {
                    display: block;
                }
            }
        }
    }
}
