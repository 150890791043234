$tundora: #444444;
$alabaster: #F8F8F8;
$akaroa: #D7C2A4;

.Message {
    width: 90%;
    margin: 0 auto;

    .Description {
        display: flex;

        margin-bottom: 0.75em;
        
        .Name {
            font-family: 'Montserrat', cursive;
            font-size: 1.25em;
            color: $tundora;

            width: 100%;
            margin-right: 0.25em;
                
            .Text {
                font-weight: 300;
                font-size: 1em;

                overflow-wrap: break-word;
                word-wrap: break-word;
                hyphens: auto;
                color: lighten($tundora, 20);
            }
        }
    }

    .Button {
        width: 100%;
        height: 3em;

        border: none;
        background: none;

        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;

        color: $tundora;

        //border-radius: 8px;
        background-color: $alabaster;

        margin-bottom: 0.75em;
        transition: all 0.15s;

        &:hover {
            color: white;
            background-color: $akaroa;
        }
    }

    .Action {
        .Button {
            &:nth-child(2) {
                background-color: $akaroa;
                color: white;
            }
        }
    }
    
    .Response {
        .Button {
            &:nth-child(2) {
                background-color: $tundora;
                color: white;
            }
        }
    }
}

@media (min-width: 720px) {
    .Message {
        width: 80%;

        .Row {
            display: flex;
            justify-content: space-between;

            .Description {
                width: 50%;
            }

            .Button {
                width: 49%;
            }
        }

        .Description {
            .Name {
                .Text {
                    line-height: 1.25em;
                }
            }
        }

        .Action {
            padding-top: 1em;
        }

        .Response {
            padding-bottom: 1em;
        }
    }
}

@media (min-width: 1024px) {
    .Message {
        width: 75%;
    }
}
