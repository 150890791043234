$tundora: #444444;

.Section {
    padding: 3em 0;

    // Non-Styling

    width: 100%;

    .Description {
        font-weight: 300;
        font-size: 1em;
        line-height: 1.75em;
        letter-spacing: 1px;
        color: $tundora;

        // Non-styling

        text-align: center;
        width: 90%;
        margin: 3em auto 0;
    }
}

@media (min-width: 720px) {
    .Section {
        .Description {
            width: 80%;
        }
    }
}

@media (min-width: 1025px) {
    .Section {
        padding: 4.5em 0;

        .Description {
            width: 60%;
            margin-top: 4em;
        }
    }
}
